import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ModuleMenu from '../../components/Modules/ModuleMenu'
import {HEATERSHAKER_MODULE_DATA} from '../../components/Modules/module-data'
import ModuleContent from '../../components/Modules/ModuleContent'

const HeaterShakerModulePage = () => (
  <Page>
    <SEO
      title="Heater-Shaker Module | Opentrons OT-2 Modular Hardware"
      description="The Opentrons Heater-Shaker Module is the most affordable fully automated thermocycler ever. Fits inside the OT-2 pipetting robot. Comparable to machines 5x its price. "
    />
    <ModuleMenu currentUrl={HEATERSHAKER_MODULE_DATA.basic.url} />
    <ModuleContent module={HEATERSHAKER_MODULE_DATA} />
  </Page>
)

export default HeaterShakerModulePage
